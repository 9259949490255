import { useEffect, useState } from "react";
import { useAuth } from "src/context/AuthContext";
import { useSnackbar } from "src/context/SnackbarContext";
import { dataFetch } from "src/utils/data-fetch";
import MakeFullScreen from "../MakeFullScreen";
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { CallReceived, Send } from "@mui/icons-material";

const utcOffset = 330 * 60 * 1000;

const TransactionHistoryComp = ({ open, onClose }) => {
  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const Id = window.localStorage.getItem("employee");

  const getDateStringFullWithTimeSync = (time) => {
    const newTime = new Date(time + utcOffset);

    const st = moment(newTime).utc();
    const a = st.format("DD-MM-YYYY hh:mm:ss A");

    return a;
  };

  const loadData = async () => {
    const url = `/api/employeetransactions/empact/transactions`;

    const ret = await dataFetch(url, await getAccessToken());

    if (ret.success) {
      setData(ret.data);
    } else {
      showSnackbar(ret.message, "error");
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MakeFullScreen
      open={open}
      onClose={onClose}
      fullScreen={"true"}
      title={"Transaction History"}
    >
      <Box>
        {data.map((item) => (
          <Card
            key={item._id}
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              m: 2,
              padding: 2,
            }}
          >
            <Stack direction="row" justifyContent={"space-between"}>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6" color="primary">
                  {item?.status}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {item?.comments}
                </Typography> */}
                <Typography variant="body2" color="text.secondary">
                  From : <b>{item?.from?.name || "Unknown"}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  To : <b>{item?.to?.name || "Unknown"}</b>
                </Typography>
              </Stack>

              <Stack direction="column" spacing={1}>
                <Typography variant="body1" color="text.primary" fontWeight={"bold"}>
                  ₹ {item.amount} /-
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  {getDateStringFullWithTimeSync(item.date)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        ))}
      </Box>
    </MakeFullScreen>
  );
};

export default TransactionHistoryComp;
{
  /* <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
            {item?.to?._id === Id ? (
              <CallReceived color="primary" />
            ) : item?.from?._id === Id ? (
              <Send color="secondary" />
            ) : null}
            <Typography variant="h6" component="div" marginLeft={1}>
              {item.type}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary">
              <strong>Amount:</strong> {item.amount}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary">
              <strong>Entity:</strong> {item.entity}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: { xs: "left", sm: "right" } }}>
            <Typography variant="body2" color="text.secondary">
              <strong>Date:</strong> {getDateStringSync(item.date)}
            </Typography>
          </Box> */
}
