import { useState } from "react";
import { Box, Typography, Card, Stack, Divider } from "@mui/material";

const AttendanceComp = () => {
  const [attendance, setAttendance] = useState(null);
  
  return (
    <Card
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        bgcolor: "grey.100", // Soft neutral background
        width: "100%",
        mx: 2,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: "primary.main", // Primary color for the title
          textAlign: "center",
        }}
      >
        Attendance Timing
      </Typography>
      <Divider sx={{ mb: 2, borderColor: "grey.300" }} /> 
      {!attendance ? (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            No attendance data available
          </Typography>
        </Box>
      ) : (
        <Stack direction="column" spacing={2} sx={{ px: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ color: "text.primary" }}>
              Morning
            </Typography>
            <Typography variant="h6" sx={{ color: "success.main" }}>
              {attendance?.morning}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ color: "text.primary" }}>
              Evening
            </Typography>
            <Typography variant="h6" sx={{ color: "success.main" }}>
              {attendance?.evening}
            </Typography>
          </Box>
        </Stack>
      )}
    </Card>
  );
};

export default AttendanceComp;
