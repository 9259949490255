import { useState } from "react";
import MakeFullScreen from "../MakeFullScreen";
import { useAuth } from "src/context/AuthContext";
import { useSnackbar } from "src/context/SnackbarContext";
import { dataFetch, dataPost } from "src/utils/data-fetch";

const {
  Box,
  Card,
  Typography,
  Input,
  Button,
  TextField,
  MenuItem,
  Stack,
} = require("@mui/material");

const RequestCompModal = ({ open, onClose }) => {
  const [openComplaintModal, setOpenComplaintModal] = useState(false);
  const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [carts, setCarts] = useState([]);
  const [val, setVal] = useState("");
  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const loadCarts = async () => {
    const url = `/api/milkcarts/empact/assignedcarts`;
    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success) {
      setCarts(ret.data);
    } else {
      showSnackbar(ret.message, "error");
    }
  };

  const initiateMaintenance = async () => {
    const url = `/api/carttimeline/empact/maintenances/add`;
    const ret = await dataPost(url, await getAccessToken(), {
      description: maintenance,
    });
    if (ret.success) {
      showSnackbar("Maintenance submitted successfully", "success");
    } else {
      alert(ret.message, "error");
    }
  };

  const postComplaint = async () => {
    const url = `/api/complaints/empact/add`;
    const ret = await dataPost(url, await getAccessToken(), { complaint: val });
    if (ret.success) {
      showSnackbar("Complaint submitted successfully", "success");
    } else {
      alert(ret.message, "error");
    }
  };

  const handleChangeMaintenanceInput = (e) => {
    setMaintenance(e.target.value);
  };

  const handleChangeInput = (e) => {
    setVal(e.target.value);
  };

  const handleSubmitMaintenance = () => {
    initiateMaintenance();
    setMaintenance("");
    setOpenMaintenanceModal(false);
  };

  const handleSubmit = () => {
    postComplaint();
    setVal("");
    setOpenComplaintModal(false);
  };

  const StyledButton = ({ onClick, icon, text, bgColor }) => (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        p: 2,
        borderRadius: 2,
        borderColor: "grey.300",
        border: 1,
        bgcolor: bgColor,
        cursor: "pointer",
        "&:hover": { bgcolor: "grey.300" },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {icon}
        <Typography variant="h6" fontWeight={500}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );

  return (
    <MakeFullScreen open={open} onClose={onClose} title={"Requests"}>
      <Box sx={{ p: 2 }}>
        <StyledButton
          onClick={() => {
            setOpenMaintenanceModal(true);
            loadCarts();
          }}
          text="Maintenance Request"
          bgColor="white"
        />
        <br />
        <StyledButton
          onClick={() => {
            setOpenComplaintModal(true);
          }}
          text="Lodge Complaint"
          bgColor="white"
        />
      </Box>

      {openComplaintModal && (
        <MakeFullScreen
          open={openComplaintModal}
          onClose={() => setOpenComplaintModal(false)}
          title={"Complaint"}
        >
          <Box sx={{ p: 2 }} alignContent={"center"}>
            <Input
              placeholder="Write your complaint here"
              multiline
              fullWidth
              rows={4}
              sx={{ mt: 2 }}
              value={val}
              onChange={handleChangeInput}
            />

            <Button variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </MakeFullScreen>
      )}

      {openMaintenanceModal && (
        <MakeFullScreen
          open={openMaintenanceModal}
          onClose={() => setOpenMaintenanceModal(false)}
          title={"Maintenance"}
        >
          <Box sx={{ p: 2 }} alignContent={"center"}>
            <Input
              placeholder="Write your maintenance description here"
              multiline
              fullWidth
              rows={4}
              sx={{ mt: 2 }}
              value={maintenance}
              onChange={handleChangeMaintenanceInput}
            />

            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={handleSubmitMaintenance}
            >
              Submit
            </Button>
          </Box>
        </MakeFullScreen>
      )}
    </MakeFullScreen>
  );
};

export default RequestCompModal;
