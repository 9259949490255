import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { dataFetch } from "../utils/data-fetch";
import { BalanceComp } from "../components/account/ProfileComp";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import Page from "../components/page";
import {
  AddOutlined,
  CalendarMonthRounded,
  DocumentScannerOutlined,
  PaymentRounded,
} from "@mui/icons-material";
import AllDecuments from "src/components/account/DocumentationComp";
import AttendanceCalander from "src/components/account/AttendanceCalander";
import MyProfileSection from "src/components/account/MyProfileSection";
import RequestCompModal from "src/components/account/RequestCompModal";
import AttendanceComp from "src/components/account/AttendanceComp";
import TransactionHistoryComp from "src/components/account/TransactionHistoryComp";

export default function B2bDashBoard() {
  const [employee, setEmployee] = useState(null);
  const [error, setError] = useState(null);
  const [allDocumentModal, setAllDocumentModal] = useState(false);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [openProfileSection, setOpenProfileSection] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [transactionOpen, setTransactionOpen] = useState(false);

  const handleTransactionModalClose = () => setTransactionOpen(false);
  const handleRequestModalClose = () => setRequestModalOpen(false);
    const handleCloseProfileSection = () => setOpenProfileSection(false);
  const handleCloseAttendanceModal = () => setAttendanceModalOpen(false);
  const handleCloseDocumentModal = () => setAllDocumentModal(false);
  const { getAccessToken } = useAuth();

  const loadData = async () => {
    const url = `/api/employees/empact/mybasicprofile?extended=true`;
    const ret = await dataFetch(url, await getAccessToken());
    if (ret.data) {
      setEmployee(ret.data);
      window.localStorage.setItem("employee", JSON.stringify(ret.data._id));
    } else setError(ret.error);
  };

  useEffect(() => {
    loadData();
  }, []);

  const StyledButton = ({ onClick, icon, text, bgColor }) => (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        p: 2,
        borderRadius: 2,
        borderColor: "grey.300",
        border: 1,
        bgcolor: bgColor,
        cursor: "pointer",
        "&:hover": { bgcolor: "grey.300" },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {icon}
        <Typography variant="h6" fontWeight={500}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );

  return (
    <Page title="Team Dashboard">
      <Stack direction="column">
        <BalanceComp employee={employee} />
        <Stack direction="column" spacing={2} padding={2}>
          <AttendanceComp />
          {/* <ProfileViewButton /> */}

          <StyledButton
            onClick={() => setAllDocumentModal(true)}
            icon={<DocumentScannerOutlined color="action" />}
            text="Documentation"
            bgColor="grey.100"
          />
          <StyledButton
            onClick={() => setRequestModalOpen(true)}
            icon={<AddOutlined color="action" />}
            text="New Request"
            bgColor="grey.100"
          />
          <StyledButton
            onClick={() => setAttendanceModalOpen(true)}
            icon={<CalendarMonthRounded color="action" />}
            text="Attendance"
            bgColor="grey.100"
          />
          <StyledButton
            onClick={() => setTransactionOpen(true)}
            icon={<PaymentRounded color="action" />}
            text="Transaction History"
            bgColor="grey.100"
          />
        </Stack>
      </Stack>

      {allDocumentModal && (
        <AllDecuments
          open={allDocumentModal}
          onClose={handleCloseDocumentModal}
          employee={employee?._id}
        />
      )}
      {attendanceModalOpen && (
        <AttendanceCalander
          open={attendanceModalOpen}
          onClose={handleCloseAttendanceModal}
        />
      )}
      {openProfileSection && (
        <MyProfileSection
          employee={employee}
          open={openProfileSection}
          onClose={handleCloseProfileSection}
        />
      )}
      {requestModalOpen && (
        <RequestCompModal
          open={requestModalOpen}
          onClose={handleRequestModalClose}
        />
      )}

      {transactionOpen && (
        <TransactionHistoryComp
          open={transactionOpen}
          onClose={handleTransactionModalClose}
        />
      )}
    </Page>
  );
}
