import * as Yup from "yup";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

// material
import {
  Stack,
  TextField,
  Modal,
  Button,
  Snackbar,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import OtopValidationModal from "./otpValidationModal";
import { dataFetch, dataPost } from "../../utils/data-fetch";
import { useAuth } from "../../context/AuthContext";
import app from "../../firebase";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PhoneLoginForm() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [identifier, setIdentifer] = useState(null);
  const { linkLogin } = useAuth();

  const [openAlert, setOpenAlert] = useState(false);

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOTPSubmit = async () => {
    if (otp.trim() === "") {
      setError("OTP is required");
      return;
    }

    try {
      setError("");
      setLoading(true);
      await OTPSubmit(otp);
    } catch (err) {
      setError("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleClose = () => {
    setOpenOTPModal(false);
  };

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required("Phone is required").min(10).max(10),
  });

  const VerifyPhoneAndsendOTP = async (phone) => {
    const url = `/api/employees/empact/login`;

    const ret = await dataPost(url, null, {
      phone: `+91${phone}`,
    });

    if (ret.data) {
      setIdentifer(ret.data);
      setOpenOTPModal(true);
    } else {
      setError(ret.message || "Phone number not registered");
    }
  };

  const OTPSubmit = async (otp) => {
    try {
      const otpVerificationUrl = `/api/employees/empact/validate-customer`;

      const ret = await dataPost(otpVerificationUrl, null, {
        otp: otp,
        identifier: identifier,
      });

      if (ret.data) {
        await linkLogin(ret.data);
        navigate("/dashboard", { replace: true });
        handleClose();
      } else {
        setError(ret.message);
      }
    } catch (error) {
      console.log(error);
      setError(error.code);
    }
  };

  const handleOtpChange = async (e) => {
    const value = e.target.value;
    setOtp(value);

    if (value.length === 4) {
      try {
        setError("");
        setLoading(true);
        await OTPSubmit(value);
      } catch (err) {
        setError("Invalid OTP. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        setError(null);

        await VerifyPhoneAndsendOTP(values.phone);
      } catch (error) {
        console.log(error);
        setError(error.code);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      {identifier === null ? (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="tel"
                label="Phone Number"
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Login
            </LoadingButton>
          </Form>
        </FormikProvider>
      ) : (
        <Stack spacing={2}>
          <TextField
            label="Enter OTP"
            variant="outlined"
            fullWidth
            value={otp}
            onChange={handleOtpChange}
            error={Boolean(error)}
            helperText={error}
            inputProps={{ maxLength: 4 }}
          />
          <Button
            variant="contained"
            color="primary"
            
            onClick={handleOTPSubmit}
            disabled={loading}
            fullWidth
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Stack>
      )}

      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position of the Snackbar
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          OTP sent successfully!
        </Alert>
      </Snackbar>
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </div>
  );
}
