import {
  Card,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  Box,
  Divider,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import MenuPopover from "../MenuPopover";
import {
  ArrowForward,
  ArrowForwardIosOutlined,
  ForkRightOutlined,
  Home,
  Logout,
} from "@mui/icons-material";
import AllDecuments from "./DocumentationComp";
import MyProfileSection from "./MyProfileSection";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: <Home />,
    linkTo: "/",
  },
  // {
  //   label: "Profile",
  //   icon: <Person />,
  //   linkTo: "#",
  // },
  // {
  //   label: "logout",
  //   icon: <Logout />,
  //   linkTo: "#",
  // },
];

export const BalanceComp = ({ employee }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); // Check if screen is md or larger
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [openProfileSection, setOpenProfileSection] = useState(false);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const handleProfileSectionClose = () => {
    setOpenProfileSection(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login", {
        replace: true,
      });
    } catch (error) {
      alert(error.code);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!employee) {
    return null; // Or return a loading state, depending on your needs
  }

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 1,
        bgcolor: "grey.300",
        py: 3,
        px: 1,
        mx: 1,
        position: "relative",
      }}
    >
      <Stack
        direction={isMdUp ? "row" : "column"} // Row on md and up, column on smaller
        justifyContent="space-between"
        alignItems={isMdUp ? "center" : "flex-start"} // Align based on screen size
        spacing={3}
        onClick={() => setOpenProfileSection(true)}
      >
        <Stack direction="row" alignItems="center" spacing={4}>
          <img
            src={"/avatar_default.jpg"}
            alt="avatar"
            ref={anchorRef}
            style={{ width: 60, height: 60, borderRadius: "50%" }}
            onClick={(event) => {
              event.stopPropagation();
              handleOpen();
            }}
          />

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{
              width: 220,
            }}
          >
            {currentUser && (
              <Box
                sx={{
                  my: 1.5,
                  px: 2.5,
                }}
              >
                {currentUser?.name && (
                  <Typography variant="subtitle1" noWrap>
                    <b>{currentUser.name}</b>
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                  noWrap
                >
                  {currentUser?.phone}
                </Typography>
              </Box>
            )}
            <Divider
              sx={{
                my: 1,
              }}
            />
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                onClick={handleClose}
                sx={{
                  typography: "body2",
                  py: 1,
                  px: 2.5,
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                >
                  {option.icon}
                </Box>
                {option.label}
              </MenuItem>
            ))}
            <Box
              sx={{
                p: 2,
                pt: 1.5,
              }}
            >
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </MenuPopover>
          <Stack direction="column" spacing={1}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
              Hi, {employee.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: employee.balance > 0 ? "green" : "red",
              }}
            >
              ₹ {employee.balance} /-
            </Typography>
          </Stack>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            position: "absolute",
            top: "50%",
            right: 16,
            height: 60,
            transform: "translateY(-50%)",
          }}
        >
          <ArrowForwardIosOutlined />
        </IconButton>
      </Stack>

      {openProfileSection && (
        <MyProfileSection
          employee={employee}
          open={openProfileSection}
          onClose={handleProfileSectionClose}
        />
      )}
    </Card>
  );
};
