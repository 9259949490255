import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  Button,
  Modal,
  useMediaQuery,
} from "@mui/material";
import MakeFullScreen from "../MakeFullScreen";
import UploadDocImage from "../UploadDocImage";
import { useAuth } from "src/context/AuthContext";
import Label from "../Label";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import { useSnackbar } from "src/context/SnackbarContext";
import { UploadFileRounded } from "@mui/icons-material";

export default function AllDocuments({ open, onClose, employee }) {
  const [currentData, setCurrentData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const { getAccessToken } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { showSnackbar } = useSnackbar();

  const loadData = async () => {
    setLoadingData(true);
    const url = `/api/employees/empact/documents`;
    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) setCurrentData(ret.data);
    else showSnackbar(ret.message, "error");
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MakeFullScreen
      open={open}
      onClose={onClose}
      title={"Document Section"}
      fullScreen={true}
    >
      {loadingData ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={isMobile ? 2 : 3}>
          {Object.entries({
            aadhaar1: "Aadhaar Front",
            aadhaar2: "Aadhaar Back",
            pan: "PAN Card",
            passbook: "Bank Details",
            picture: "Picture",
            electricity: "Electricity Bill",
            guardian: "Parent/Guardian ID",
            police_ver: "Police Verification",
            dl: "Driving License",
            last_offer: "Last Offer",
            last_salary_slip: "Last Salary Slip",
          }).map(([key, title]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <OneDocSection
                doc={currentData[key]}
                category={key}
                title={title}
                reload={loadData}
                empId={employee}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </MakeFullScreen>
  );
}

const OneDocSection = ({ doc, category, title, reload, empId }) => {
  const ProductImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  });

  const [uploadModalState, setUploadModalState] = useState(false);

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: 3,
        boxShadow: 4,
        height: 200,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={5} sx={{ position: "relative" }}>
          {doc?.url ? (
            <ProductImgStyle
              alt={title}
              src={doc?.url}
              sx={{ borderRadius: 2, width: "150px", height: "150px" }}
            />
          ) : (
            <Box
              sx={{
                bgcolor: "grey.100",
                width: "100%",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                align="center"
              >
                No Image Available
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setUploadModalState(true)}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  borderRadius: "50%",
                  minWidth: 40,
                  height: 40,
                  p: 0,
                }}
              >
                <UploadFileRounded />
              </Button>
            </Box>
          )}
        </Grid>

        {/* Right side for document details */}
        <Grid item xs={7}>
          <Stack spacing={1.5}>
            <Typography
              variant="h6"
              sx={{ textTransform: "capitalize", fontWeight: "bold" }}
            >
              {title}
            </Typography>
            {doc?.status && (
              <Label
                variant="filled"
                color={doc.status === "pending" ? "error" : "info"}
                sx={{ textTransform: "uppercase", borderRadius: 1, px: 1.5 }}
              >
                {doc.status}
              </Label>
            )}
            <Typography variant="subtitle2" color="text.secondary">
              {doc?.data || "No additional data available"}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      {/* Upload Modal */}
      {uploadModalState && (
        <Modal
          open={uploadModalState}
          onClose={() => setUploadModalState(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 3,
              p: 4,
              boxShadow: 6,
            }}
          >
            <UploadDocImage
              category={category}
              empId={empId}
              handleM1Close={() => {
                setUploadModalState(false);
                reload();
              }}
            />
          </Box>
        </Modal>
      )}
    </Card>
  );
};
