import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import MakeFullScreen from "../MakeFullScreen";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/context/AuthContext";

const StyledCalendar = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  ".react-calendar": {
    border: "none",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    overflow: "hidden",
  },
  ".react-calendar__tile--now": {
    backgroundColor: "#e0f7fa",
  },
  ".present": { backgroundColor: "#4caf50", color: "white" },
  ".absent": { backgroundColor: "#f44336", color: "white" },
  ".halfday": { backgroundColor: "#ff9800", color: "white" },
});

const AttendanceCalander = ({ open, onClose }) => {
  const [date, setDate] = useState(new Date());
  const [attendance, setAttendance] = useState([]);
  const { getAccessToken } = useAuth();

  const loadData = async () => {
    const ret = await dataFetch(
      "/api/employees/empact/myattendance",
      await getAccessToken()
    );
    if (ret.data) {
      setAttendance(ret.data);
    }
  };

  const getAttendanceStatus = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    const attendanceEntry = attendance.find(
      (entry) =>
        dayjs.unix(entry.date / 1000).format("YYYY-MM-DD") === formattedDate
    );
    return attendanceEntry ? attendanceEntry.leave_status : null;
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MakeFullScreen open={open} onClose={onClose} title={"Attendance Calendar"}>
      <StyledCalendar>
        <Calendar
          onChange={setDate}
          value={date}
          tileClassName={({ date }) => {
            const status = getAttendanceStatus(date);
            return status === "present"
              ? "present"
              : status === "absent"
              ? "absent"
              : status === "halfday"
              ? "halfday"
              : null;
          }}
        />
      </StyledCalendar>
    </MakeFullScreen>
  );
};

export default AttendanceCalander;
