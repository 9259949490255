import {
  AccountBox,
  CalendarTodayOutlined,
  Category,
  DateRange,
  GroupAddRounded,
  GroupRounded,
  LocationCity,
  LocationCityTwoTone,
  PermIdentityOutlined,
  RollerShades,
  TableRows,
} from "@mui/icons-material";
import {
  Box,
  Stack,
  Avatar,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  TableBody,
  Card,
} from "@mui/material";
import { styled } from "@mui/system";
import MakeFullScreen from "../MakeFullScreen";
import moment from "moment";
import { useMemo, useState } from "react";

const utcOffset = 330 * 60 * 1000;

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  fontSize: 36,
  backgroundColor: theme.palette.primary.main,
  color: "white",
}));

const SectionBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
});

const getDateStringSync = (time) => {
  const newTime = new Date(time + utcOffset);
  return moment(newTime).utc().format("DD-MM-YY");
};

const MyProfileSection = ({ employee, open, onClose }) => {
  const employeeInitial = useMemo(() => employee?.name?.[0] || "A", [employee]);

  const [selectedTab, setSelectedTab] = useState("workPlace");

  return (
    <MakeFullScreen
      open={open}
      onClose={onClose}
      title={"My Profile"}
      fullScreen={true}
    >
      <Stack direction="column" spacing={2} padding={1}>
        <SectionBox sx={{ textAlign: "center" }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="left"
          >
            <StyledAvatar>{employeeInitial}</StyledAvatar>
            <Stack spacing={0.5}>
              <Typography variant="h5" fontWeight="bold">
                {employee?.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {employee?.phone}
              </Typography>
            </Stack>
          </Stack>
        </SectionBox>

        <Divider variant="middle" />

        <Stack
          direction="row"
          spacing={2}
          padding={1}
          justifyContent="space-between"
        >
          {["workPlace", "personalDetails", "payment"].map((tab) => (
            <Box
              key={tab}
              onClick={() => setSelectedTab(tab)}
              sx={{
                cursor: "pointer",
                paddingBottom: "8px",
                borderBottom: selectedTab === tab ? "2px solid" : "none",
                borderColor: "primary.main",
              }}
            >
              <Typography
                variant="subtitle1"
                color={selectedTab === tab ? "primary.main" : "text.secondary"}
              >
                {tab === "workPlace"
                  ? "Work Place"
                  : tab === "personalDetails"
                  ? "Personal Details"
                  : "Payment"}
              </Typography>
            </Box>
          ))}
        </Stack>

        {selectedTab === "workPlace" &&
          [
            {
              icon: <PermIdentityOutlined />,
              label: "Employee ID",
              value: employee?.employee_det?.uin,
            },
            {
              icon: <LocationCityTwoTone />,
              label: "Plant",
              value: employee?.franchisee?.name,
            },
            {
              icon: <RollerShades />,
              label: "Designation",
              value: employee?.employee_det?.designation || "--",
            },

            {
              icon: <GroupRounded />,
              label: "Department",
              value: employee?.employee_det?.department?.name || "--",
            },
            {
              icon: <CalendarTodayOutlined />,
              label: "Date of Joining",
              value:
                getDateStringSync(employee?.employee_det?.join_date) || "--",
            },
            {
              icon: <Category />,
              label: "Work Status",
              value: employee?.employee_det?.status || "--",
            },
            {
              icon: <LocationCity />,
              label: "Work Location",
              value: employee?.franchisee?.city || "--",
            },
            {
              icon: <DateRange />,
              label: "Leaves Per Month",
              value: `${employee?.employee_det?.leaves_pm || "--"} Days`,
            },
            {
              icon: <AccountBox />,
              label: "PF Account Number",
              value: employee?.employee_det?.pf_account || "--",
            },
          ].map(({ icon, label, value }, index) => (
            <SectionBox key={index}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box color="primary.main">{icon}</Box>
                <Stack>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    fontWeight="bold"
                  >
                    {label}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {value}
                  </Typography>
                </Stack>
              </Stack>
            </SectionBox>
          ))}

        {selectedTab === "personalDetails" &&
          [
            {
              icon: <PermIdentityOutlined />,
              label: "TOFF CODE",
              value: employee?.employee_det?.toff_code || "--",
            },
            {
              icon: <LocationCityTwoTone />,
              label: "State",
              value: employee?.employee_det?.state || "--",
            },
            {
              icon: <RollerShades />,
              label: "Blood Group",
              value: employee?.employee_det?.blood_group || "--",
            },

            {
              icon: <CalendarTodayOutlined />,
              label: "Date of Birth",
              value: getDateStringSync(employee?.employee_det?.dob) || "--",
            },
            {
              icon: <CalendarTodayOutlined />,
              label: "T-Shirt Size",
              value: employee?.employee_det?.tshirt_size || "--",
            },
          ].map(({ icon, label, value }, index) => (
            <SectionBox key={index}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box color="primary.main">{icon}</Box>
                <Stack>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    fontWeight="bold"
                  >
                    {label}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {value}
                  </Typography>
                </Stack>
              </Stack>
            </SectionBox>
          ))}

        {selectedTab === "payment" && (
          <>
            <Card
              sx={{
                p: 3,
                borderRadius: 2,
                boxShadow: 2,
                bgcolor: "background.paper",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  textAlign: "center",
                  color: "primary.main",
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  paddingBottom: 2,
                  borderBottom: "2px solid",
                  borderColor: "primary.light",
                  mb: 3,
                }}
              >
                BANK ACCOUNT DETAILS
              </Typography>
              {employee.employee_det.bank_account ? (
                <Stack spacing={3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      Name:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.name || "--"}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      Branch:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.branch || "--"}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      Account Number:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.number || "--"}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      IFSC:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.ifsc || "--"}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      Bank Name:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.bank || "--"}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      UPI Account:
                    </Typography>
                    <Typography color="text.secondary">
                      {employee?.employee_det?.bank_account?.upi || "--"}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  textAlign="center"
                  sx={{ mt: 3 }}
                >
                  No bank account details available
                </Typography>
              )}
            </Card>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Component</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee?.employee_det?.salary_structure?.map(
                    (salary, index) => (
                      <TableRow key={index}>
                        <TableCell>{salary.name}</TableCell>
                        <TableCell align="right">
                          ₹ {parseInt(salary.actual_amount) / 12 || "--"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Stack>
    </MakeFullScreen>
  );
};

export default MyProfileSection;
