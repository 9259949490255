import { Card, Stack, Container, Typography, Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import PhoneLoginForm from "./loginForm";
import Page from "../../components/page";
import MHidden from "src/components/@material-extend/MHidden";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function B2BLogin() {
  return (
    <RootStyle title="Login | Milkvilla">
      {/* <MHidden width="mdDown"> */}
      <MHidden width="smDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img
            src="/static/illustration_login.png"
            alt="login"
            style={{ width: "100%", maxWidth: 400 }} // Limit image width on larger screens
          />
        </SectionStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Sign in to Milkvilla Bulk Sale
              </Typography>
              <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
                Enter your details below.
              </Typography>
            </Stack>

            {/* Form Component */}
            <PhoneLoginForm />

            {/* Spacing for mobile */}
            <Box sx={{ mt: 3 }} />
          </ContentStyle>
        </Container>
      </MHidden>

      {/* Mobile view */}
      <MHidden width="mdUp">
        <Container maxWidth="sm" 
        // sx={{
        //   backgroundImage: `url('/static/bg-image.png')`,
        // }}
        >
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Sign in to Milkvilla Team
              </Typography>
              <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
                Enter your details below.
              </Typography>
            </Stack>

            {/* Form Component */}
            <PhoneLoginForm />

            {/* Spacing for mobile */}
            <Box sx={{ mt: 3 }} />
          </ContentStyle>
        </Container>
      </MHidden>
    </RootStyle>
  );
}
